import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "@components/Layout"
import PostCard from "@components/PostCard"
import Seo from "@components/Seo"
import Pagination from "@components/Pagination"

const ProjectsArchive = ({
  data,
  pageContext: { nextPagePath, previousPagePath, currentPage, numPages },
}) => {
  const { projects, pageMeta } = data

  return (
    <Layout>
      <Seo yoastSeo={pageMeta?.seo} />

      <section className="bg-slate-100 dark:bg-slate-800 py-20">
        <div className="container--boxed">
          <h1 className="h1 text-center">Projects</h1>
        </div>
      </section>

      <section className="mt-12">
        <div className="container--boxed">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-9 sm:gap-x-6 sm:gap-y-10  lg:gap-x-10 lg:gap-y-14">
            {projects.edges.map(({ node }, index) => {
              return (
                <PostCard
                  key={node.id}
                  title={node.title}
                  excerpt={node.excerpt.replace(/<[^>]+>/g, "")}
                  image={{
                    gatsbyImage:
                      node.featuredImage?.node?.localFile?.childImageSharp
                        ?.gatsbyImageData,
                    alt: node.featuredImage?.node?.altText || node.title,
                  }}
                  link={{
                    type: "internal",
                    url: `/projects/${node.slug}/`,
                  }}
                />
              )
            })}
          </div>

          <Pagination
            {...{ previousPagePath, nextPagePath, currentPage, numPages }}
            classOverrides="mt-20"
          />
        </div>
      </section>
    </Layout>
  )
}

export default ProjectsArchive

export const pageQuery = graphql`
  query WordPressProjectsArchive($offset: Int!, $postsPerPage: Int!) {
    projects: allWpProject(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      edges {
        node {
          ...WpProjectCardFields
        }
      }
    }

    pageMeta: wpPage(slug: { eq: "projects" }) {
      ...WpPageFields
    }
  }
`
